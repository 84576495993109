<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header-v2";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
import PDFViewer from "@/components/pdfViewer";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Lihat Truck Driver",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, PDFViewer },
  data() {
    return {
      config: {
        redirect: "truck_owner",
        api: "driver",
        vehicle:
          "vehicle?orderby=id&sort=desc&page=1&per_page=10&&owner=driver&owner_id=",
        update_account: "driver/update_account_status",
        detail_vehicle: "vehicle/",
        account_bank:
          "bank_account?owner=driver&orderby=id&sort=desc&owner_id=",
      },
      tableData1: [
        {
          id: 1,
          name: "Timoteo Lyddy",
          no_sim: "00900900000000090",
          no_ktp: "000191000100101",
          telepon: "089000000101",
          alamat: "Jl. ABC, No. 1",
          status: "Menunggu Persetjuan",
        },
        {
          id: 2,
          name: "Cherye Bleby",
          no_sim: "00900900000000090",
          no_ktp: "000191000100102",
          telepon: "089000000102",
          alamat: "Jl. ABC, No. 2",
          status: "Aktif",
        },
      ],
      fields1: [
        { key: "no_ktp", label: "No KTP", sortable: true },
        { key: "no_sim", label: "No SIM", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "telepon", label: "Telepon", sortable: true },
        { key: "alamat", label: "Alamat", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action" },
      ],

      tableData2: [
        {
          id: 1,
          tipe: "CDD",
          no_sim: "00900900000000090",
          no_plat: "D 1990 AB",
          warna: "Black",
          merk: "Kawasaki",
          status: "Aktif",
        },
        {
          id: 2,
          tipe: "PICKUP",
          no_sim: "00900900000000090",
          no_plat: "D 1990 AD",
          warna: "Red",
          merk: "Kawasaki",
          status: "Aktif",
        },
      ],
      fields2: [
        { key: "no_sim", label: "No STNK", sortable: true },
        { key: "no_plat", label: "No Plat", sortable: true },
        { key: "tipe", label: "Tipe", sortable: true },
        { key: "merk", label: "Merk", sortable: true },
        { key: "warna", label: "Warna", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "action" },
      ],
      title_modal_armada: null,
      file_path_modal_armada: null,

      tableData3: [],
      fields3: [
        { key: "bank_name", label: "Nama" },
        { key: "account_number", label: "Rekening" },
        { key: "account_name", label: "Atas Nama" },
      ],
      title: "Lihat Truck Driver",
      items: [
        {
          text: "Truck Driver",
          href: "/truck-driver",
        },
        {
          text: "Lihat Detail",
          href: "/truck-driver",
          active: true,
        },
      ],
      showArmada: true,
      model: {
        code: "",
        ktp: {},
        sim: {},
        vaccines: {},
      },

      vehicle_id: null,
      vehicle_data: {},
      badge: "0009009000",
      type_modal: null,
      title_modal: "",
      file_path_modal: null,
      reject_reason: null,
    };
  },
  methods: {
    getData() {
      let _ = this;
      api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        _.model = response.data.data;
        this.badge = _.model.code;
        _.getDataArmada();
        _.getAccountBank();
      });
    },

    getAccountBank() {
      let _ = this;
      api
        .get(_.config.account_bank + this.$route.params.id)
        .then((response) => {
          _.tableData3 = response.data.data;
        });
    },

    getDataArmada() {
      let _ = this;
      api.get(_.config.vehicle + this.$route.params.id).then((response) => {
        if (response.data.data.rows.length > 0) {
          _.vehicle_id = response.data.data.rows[0].id;
          _.getDataArmadaDetail();
        } else {
          _.showArmada = false;
        }
      });
    },

    getDataArmadaDetail() {
      let _ = this;
      api.get(_.config.detail_vehicle + _.vehicle_id).then((response) => {
        _.vehicle_data = response.data.data;

        console.log(_.vehicle_data.photos, "photos");
        _.vehicle_data.photos = JSON.parse(_.vehicle_data.photos);
        console.log(_.vehicle_data, "ini Vehicle Data");
      });
    },

    show_modal(types, data) {
      this.$refs["show-modal"].show();
      let _ = this;
      if (types == 1) {
        _.title_modal = "KTP";
        _.file_path_modal = _.model.ktp.ktp_path;
      } else if (types == 2) {
        _.title_modal = "Selfie KTP";
        _.file_path_modal = _.model.ktp.selfie_ktp_path;
      } else if (types == 3) {
        _.title_modal = "SIM";
        _.file_path_modal = _.model.sim.sim_path;
      } else if (types == 4) {
        _.title_modal = "Selfie SIM";
        _.file_path_modal = _.model.sim.selfie_sim_path;
      } else {
        _.title_modal = "Vaccine";
        _.file_path_modal = data;
      }
    },

    changeStatus(status) {
      let _ = this;
      if (status != "ditolak") {
        let data = { account_status: status };
        api
          .put(_.config.update_account + "/" + this.$route.params.id, data)
          .then(() => {
            _.$toast("Status account sukses diubah", {
              icon: "fas fa-check",
              type: "success",
            });
            _.getData();
          });
      } else {
        this.$refs["show-modal-reject"].show();
      }
    },

    changeReject() {
      let _ = this;
      let data = {
        account_status: "ditolak",
        reject_reason: this.reject_reason,
      };
      api
        .put(_.config.update_account + "/" + this.$route.params.id, data)
        .then(() => {
          _.$toast("Status Account sukses diubah", {
            icon: "fas fa-check",
            type: "success",
          });
          _.getData();
          this.$refs["show-modal-reject"].hide();
        });
    },

    show_modal_armada(types) {
      this.$refs["show-modal_armada"].show();
      let _ = this;
      if (types == 1) {
        _.title_modal_armada = "STNK";
        _.file_path_modal_armada = _.vehicle_data.stnk_path;
      } else if (types == 2) {
        _.title_modal_armada = "KIR";
        _.file_path_modal_armada = _.vehicle_data.kir_path;
      }
    },
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    $route(newVal, oldVal) {
      // TODO: react to navigation event.
      // params cotains the current route parameters
      if (newVal != oldVal) {
        let _ = this;
        _.getData();
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :badge="badge" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <h6 class="text-warning text-capitalize">
                  {{ model.account_status }} - {{ model.document_status }}
                </h6>
                <h4>{{ model.name }}</h4>
                <h6>{{ model.address }}</h6>
                <h6>{{ model.email }}</h6>
              </div>

              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="ml-3">
                  <label class="mb-0 form-label font-weight-light"
                    >Alamat</label
                  >
                  <h6>{{ model.address }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Telepon</label
                  >
                  <h6>{{ model.phone }}</h6>
                </div>
              </div>

              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Tanggal Registrasi</label
                  >
                  <h6>{{ model.registration_at | moment("YYYY MMM DD") }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Aktifitas Terakhir</label
                  >
                  <h6 v-if="model.last_activity_at != null">
                    {{ model.last_activity_at | moment("YYYY MMM DD") }}
                  </h6>
                  <h6 v-else>-</h6>
                </div>
              </div>

              <div class="col-md-3">
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Total Pendapatan</label
                  >
                  <h6>{{ model.income | currency }}</h6>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <b-tabs pills class="navtab-bg mt-2" content-class="p-3">
                        <b-tab active>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">Driver</h6>
                          </template>
                          <div class="row">
                            <div class="col-md-4">
                              <h5>KTP</h5>
                            </div>

                            <div class="col-md-4">
                              <h5>SIM</h5>
                            </div>

                            <div class="col-md-4">
                              <h5>Vaksin</h5>
                            </div>

                            <div
                              class="col-md-4"
                              style="
                                border-right: 2px solid #333;
                                border-radius: 2px;
                              "
                            >
                              <label class="mb-0 form-label font-weight-light"
                                >No</label
                              >
                              <h6>{{ model.ktp.ktp_no }}</h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Nama</label
                              >
                              <h6>{{ model.ktp.name }}</h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Jenis Kelamin</label
                              >
                              <h6>
                                {{
                                  model.ktp.gender == "L"
                                    ? "Laki - Laki"
                                    : "Perempuan"
                                }}
                              </h6>

                              <label
                                class="mb-0 mt-0 form-label font-weight-light"
                                >Tanggal Lahir</label
                              >
                              <h6>
                                {{ model.dob | moment("DD MMMM YYYYY") }}
                              </h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Alamat</label
                              >
                              <h6>{{ model.ktp.address }}</h6>

                              <div class="row">
                                <div
                                  class="col-md-4 ml-2 mt-2 text-center"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 1px;
                                  "
                                >
                                  <h6 class="text-center">KTP</h6>
                                  <h6 class="manual-link">
                                    <a @click="show_modal(1)">Lihat</a>
                                  </h6>
                                </div>

                                <div
                                  class="col-md-4 ml-2 mt-2 text-center"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 1px;
                                  "
                                >
                                  <h6 class="text-center">Selfie KTP</h6>
                                  <h6 class="manual-link">
                                    <a @click="show_modal(2)">Lihat</a>
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4"
                              style="
                                border-right: 2px solid #333;
                                border-radius: 2px;
                              "
                            >
                              <label class="mb-0 form-label font-weight-light"
                                >No</label
                              >
                              <h6>{{ model.sim ? model.sim.sim_no : "-" }}</h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Nama</label
                              >
                              <h6>{{ model.sim ? model.sim.name : "-" }}</h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Tipe SIM</label
                              >
                              <h6>
                                SIM {{ model.sim ? model.sim.type : "-" }}
                              </h6>

                              <label class="mb-0 form-label font-weight-light"
                                >Masa Berlaku
                              </label>
                              <h6 v-if="model.sim">
                                {{
                                  model.sim.expired_at | moment("DD MMM YYYY")
                                }}
                              </h6>
                              <h6 v-else>-</h6>

                              <div class="row">
                                <div
                                  class="col-md-4 ml-2 mt-2 text-center"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 1px;
                                  "
                                >
                                  <h6 class="text-center">SIM</h6>
                                  <h6 class="manual-link">
                                    <a @click="show_modal(3)">Lihat</a>
                                  </h6>
                                </div>

                                <div
                                  class="col-md-4 ml-2 mt-2 text-center"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 1px;
                                  "
                                >
                                  <h6 class="text-center">Selfie SIM</h6>
                                  <h6 class="manual-link">
                                    <a @click="show_modal(4)">Lihat</a>
                                  </h6>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="row">
                                <div
                                  class="col-md-9 ml-2 mb-2 p-3"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 1px;
                                  "
                                  v-for="(i, index) in model.vaccines"
                                  :key="index"
                                >
                                  <div class="row">
                                    <div class="col-md-6">
                                      <h6>Sertifikat</h6>
                                      <h6>{{ i.vaccine_no }}</h6>
                                    </div>
                                    <div class="col-md-6 text-right">
                                      <h6>Dosis</h6>
                                      <h6>{{ index + 1 }}</h6>
                                    </div>

                                    <div class="col-md-12 manual-link">
                                      <a @click="show_modal(4, i.vaccine_path)"
                                        >Lihat</a
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">Armada</h6>
                          </template>
                          <div v-if="showArmada" class="row">
                            <div class="col-md-4">
                              <h5>STNK</h5>
                            </div>

                            <div class="col-md-4">
                              <h5>KIR</h5>
                            </div>

                            <div class="col-md-4">
                              <h5>Foto</h5>
                            </div>

                            <div
                              class="col-md-4"
                              style="
                                border-right: 2px solid #333;
                                border-radius: 2px;
                              "
                            >
                              <div class="row">
                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >No</label
                                  >
                                  <h6>{{ vehicle_data.stnk_number }}</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >No Plat</label
                                  >
                                  <h6>{{ vehicle_data.plat_number }}</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Merk</label
                                  >
                                  <h6>{{ vehicle_data.merk }}</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Kategori Armada</label
                                  >
                                  <h6>
                                    {{ vehicle_data.vehicle_category.name }}
                                  </h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Warna</label
                                  >
                                  <h6>{{ vehicle_data.color }}</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 mt-0 form-label font-weight-light"
                                    >Model</label
                                  >
                                  <h6>
                                    {{
                                      vehicle_data.model
                                        ? vehicle_data.model
                                        : "-"
                                    }}
                                  </h6>
                                </div>

                                <div class="col-md-12">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Masa Berlaku</label
                                  >
                                  <h6>
                                    {{
                                      vehicle_data.stnk_expired_at
                                        | moment("DD MMM YYYYY")
                                    }}
                                  </h6>
                                </div>

                                <div
                                  class="col-md-4 mt-2"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 2px;
                                  "
                                >
                                  <h6 class="text-center">STNK</h6>
                                  <div
                                    class="text-center col-md-12 manual-link"
                                  >
                                    <a @click="show_modal_armada(1)">Lihat</a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              class="col-md-4"
                              style="
                                border-right: 2px solid #333;
                                border-radius: 2px;
                              "
                            >
                              <div class="row">
                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >No</label
                                  >
                                  <h6>
                                    {{ vehicle_data.kir_number }}
                                  </h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Masa Berlaku</label
                                  >
                                  <h6>
                                    {{
                                      vehicle_data.kir_expired_at
                                        | moment("DD MMM YYYYY")
                                    }}
                                  </h6>
                                </div>

                                <div class="col-md-4">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Panjang</label
                                  >
                                  <h6>{{ vehicle_data.length }} m</h6>
                                </div>

                                <div class="col-md-4">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Lebar</label
                                  >
                                  <h6>{{ vehicle_data.width }} m</h6>
                                </div>

                                <div class="col-md-4">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Tinggi</label
                                  >
                                  <h6>{{ vehicle_data.height }} m</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Volume Box</label
                                  >
                                  <h6>{{ vehicle_data.volume }} cbm</h6>
                                </div>

                                <div class="col-md-6">
                                  <label
                                    class="mb-0 form-label font-weight-light"
                                    >Daya Angkut</label
                                  >
                                  <h6>{{ vehicle_data.tonase }} Kg</h6>
                                </div>

                                <div
                                  class="col-md-4 mt-2 ml-2"
                                  style="
                                    border: 2px solid #333333;
                                    border-radius: 2px;
                                  "
                                >
                                  <h6 class="text-center">KIR</h6>
                                  <div
                                    class="text-center col-md-12 manual-link"
                                  >
                                    <a @click="show_modal_armada(2)">Lihat</a>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="row">
                                <div
                                  class="col-md-5 ml-1 mb-2 p-3"
                                  v-for="(i, index) in vehicle_data.photos"
                                  :key="index"
                                >
                                  <embed
                                    :src="i.path"
                                    alt=""
                                    height="75"
                                    width="75"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div v-else class="row">
                            <div class="col-md-12">
                              <div
                                class="col-md-12 text-center border border-primary rounded"
                              >
                                <h5 class="p-4">Armada belum tersedia</h5>
                              </div>
                            </div>
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">
                              Akun Bank ({{ tableData3.total_data }})
                            </h6>
                          </template>
                          <div class="row">
                            <b-table
                              show-empty
                              :items="tableData3.rows"
                              :fields="fields3"
                            >
                            </b-table>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <b-button variant="light" to="/truck-driver">Kembali</b-button>
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatus('aktif')"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  @click="changeStatus('ditolak')"
                  >Tolak</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-warning"
                  @click="changeStatus('suspend')"
                  >Suspend</b-button
                >
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatus('aktif')"
                  >Aktifkan</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-center"
        centered
        ref="show-modal"
        size="lg"
        min-
        :title="title_modal"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body min-height-modal">
          <!-- <embed
            :src="
              file_path_modal != null
                ? file_path_modal
                : 'https://via.placeholder.com/450?text=File+Not+Found'
            "
            class="embed-classes"
          /> -->
          <PDFViewer :file="file_path_modal" />
        </div>
      </b-modal>

      <!-- Armada Detail -->
      <b-modal
        id="modal-center"
        centered
        ref="show-modal_armada"
        size="lg"
        min-
        :title="title_modal_armada"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body min-height-modal">
          <!-- <embed
            :src="
              file_path_modal_armada != null
                ? file_path_modal_armada
                : 'https://via.placeholder.com/450?text=File+Not+Found'
            "
            class="embed-classes"
          /> -->
          <PDFViewer :file="file_path_modal_armada" />
        </div>
      </b-modal>

      <b-modal
        id="modal-center"
        centered
        ref="show-modal-reject"
        size="lg"
        title="Account Status"
        title-class="font-18"
        hide-footer
      >
        <div class="modal-body">
          <ValidationObserver v-slot="{ handleSubmit }">
            <b-form
              @submit.prevent="handleSubmit(changeReject)"
              class="form-horizontal"
            >
              <div class="row">
                <div class="col-md-12">
                  <ValidationProvider
                    name="Alasan Tolak"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-form-group
                      id="input-group-2"
                      label="Alasan Tolak"
                      label-for="input"
                    >
                      <textarea
                        class="form-control"
                        id="exampleFormControlTextarea1"
                        rows="4"
                        v-model="reject_reason"
                      ></textarea>
                      <p class="fs-6 text-danger">
                        {{ errors[0] }}
                      </p>
                    </b-form-group>
                  </ValidationProvider>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <b-button type="submit" variant="primary" class="w-100"
                        >Tolak</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </div>
      </b-modal>
    </div>
  </Layout>
</template>
